@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: dark;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0D1117;
}

.loading {
  cursor: wait;
}

strong {
  font-family: 'Roboto', sans-serif;
}

.snackbar-wrapper {
  position: fixed; /* Fixed positioning to keep it in view */
  top: 0; /* Stick it to the top of the page */
  left: 50%; /* Horizontally center it */
  transform: translateX(-50%); /* Adjust for centering */
  width: auto; /* Adjust width as necessary */
  z-index: 1300; /* Ensure it's above other content */
}

textarea {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  overflow-y: auto;
  resize: none;
  border-radius: 6px;
  padding: 4px 12px;
  background-color: #0B0D0E;
  border-width: thin;
  border-color: #4b5563;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

